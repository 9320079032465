<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <a-tooltip placement="top">
          <template slot="title"><span>Add Employee</span></template>
          <a href="javascript: void(0);" v-if="user.role.includes('head_of_admin')" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-update-employee-modal @click="$bvModal.show('add-update-employee-modal'), resetForm()">
            <i class="fa fa-plus"/> Add Employee
          </a>
        </a-tooltip>
        <table class="table table-sm table-bordered text-center" id="employee_list">
          <thead>
          <tr>
            <th>SL</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Department</th>
            <th>Employee ID</th>
            <th>Phone No</th>
            <th>Organization</th>
            <th>Status</th>
            <th v-if="user.role.includes('head_of_admin') || user.role.includes('software_admin')">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(employee, index) in employees" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ employee.name }}</td>
            <td>{{ employee.designation }}</td>
            <td>{{ employee.department ? employee.department.department_name : '' }}</td>
            <td>{{ employee.employee_id }}</td>
            <td>{{ employee.phone_no }}</td>
            <td>{{ employee.organization || '' }}</td>
            <td><b-badge :variant="employee.status ? 'success' : 'danger'">{{ employee.status ? 'Active' : 'Inactive' }}</b-badge></td>
            <td  v-if="user.role.includes('head_of_admin') || user.role.includes('software_admin')">
              <a v-if="user.role.includes('head_of_admin') || user.role.includes('software_admin')" class="btn btn-sm btn-primary mr-2" href="javascript: void(0);" @click.prevent="edit(employee.id), $bvModal.show('add-update-employee-modal')"><i class="fa fa-edit mr-1"></i>Update</a>
              <a v-if="user.role.includes('head_of_admin')" class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(employee.id)"><i class="fa fa-trash mr-1"></i>Delete</a>
            </td>
          </tr>
          </tbody>
        </table>
        <a-skeleton active :loading="loading"></a-skeleton>
        <h5 class="text-center text-secondary" v-if="employees.length === 0 && !flag">
          <Empty :image="simpleImage"></Empty>
        </h5>
      </div>
    </div>
    <add-update-employee
      :employee="employee"
      :btnEdit="btnEdit"
      :show="show"
      @getEmployees="getEmployees"
      @resetForm="resetForm"
      ref="addUpdateEmployee">
    </add-update-employee>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import addUpdateEmployee from '@/views/employee/modal/addUpdateEmployee'
import $ from 'jquery'
import { mapGetters } from 'vuex'

export default {
  name: 'List',
  components: { Empty, addUpdateEmployee },
  data() {
    return {
      employees: [],
      employee: {},
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      show: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getEmployees()
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  methods: {
    getEmployees() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/employees')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.employees = response.data.employees
          setTimeout(function() {
            $('#employee_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit(id) {
      this.employee = {}
      this.btnEdit = true
      apiClient.get('api/employees/' + id + '/edit').then(response => {
        if (!response.data.error) {
          this.employee = response.data.employee
        }
      })
    },
    onDelete(employeeId) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/employees/' + employeeId).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#employee_list').DataTable().destroy()
              this.getEmployees()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    resetForm() {
      this.show = false
      this.btnEdit = false
      this.employee = {}
    },
  },
}
</script>

<style scoped>
.item:last-child .comma {
  display: none;
}
</style>
